.input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.label {
  float: left;
  display: block;
  margin-bottom: 8px;
}
.text {
  outline: none;
  border: 1px solid #ccc;
  background-color: #f2f2f2;
  font-size: 1.1rem;
  padding: 10px 0 10px 10px;
  width: 95%;
  margin: 10px 0;
  border-radius: 7px;
}

.text:focus {
  outline: none;
  background-color: #ccc;
}
/* Not needed for artist portfolio */
textarea {
  width: 95%;
  height: 1000px;
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.07);
  border-color: -moz-use-text-color #ffffff #ffffff -moz-use-text-color;
  border-image: none;
  border-radius: 6px 6px 6px 6px;
  border-style: none solid solid none;
  border-width: medium 1px 1px medium;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
  color: #555555;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1em;
  line-height: 1.4em;
  padding: 10px;
  transition: background-color 0.2s ease 0s;
}

textarea:focus {
  background: none repeat scroll 0 0 #ffffff;
  outline-width: 0;
}
