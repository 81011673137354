.nav {
  height: 75px;
  left: 50%;
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  z-index: 10;
}
.nav a {
  text-decoration: none;
}
.headerLogo {
  margin: 0;
  margin: auto 0;
}
.desktop_container {
  list-style-type: none;
  margin: auto 0;
}
.desktop_container li {
  float: left;
  margin: auto 0;
}

.desktop_container li a {
  display: block;
  text-align: center;
  padding: 16px;
  text-decoration: none;
  font-size: 1.1rem;
}
.desktop_container li a:hover {
  color: #4bb781;
}
.regular_nav_li a {
  color: white;
}
.tracking_button {
  display: inherit;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  margin: 0;
  padding: 0;
  justify-content: inherit;
  width: 100%;
  text-decoration: inherit;
  height: 100%;
  font-size: inherit;
  border-radius: inherit;
  background-color: inherit;
  color: inherit;
  align-items: inherit;
  float: inherit;
  box-shadow: none;
}

.outlined_nav_li {
  background-color: white;
  border-radius: 40px;
}
.outlined_nav_li a {
  color: #333;
  width: 100%;
  width: 100%;


}
.mobile_outlined_nav_li {
  border-top: 1px solid #4bb781;
  background-color: #3d5aaf;
  width: 100%;
  margin: 60px 0 0 0;
}

.hamburger_container {
  display: 'flex';
  align-items: center;
  margin: auto 0;
  padding: 0;
  cursor: pointer;
}
.wideLink {
  display: block;
  height: 100%;
  width: 100%;
}

@media (max-width: 980px) {
  .desktop_container {
    display: none;
  }
}
@media (min-width: 980.5px) {
  .hamburger_container {
    display: none;
  }
}
@media (max-height: 400px) {
  .nav {
    height: 65px;
  }
}

.drawerlist_container {
  list-style-type: none;
  margin: 0;
  padding: 0;
  align-items: center;
}
.drawerlist_container li {
  border-bottom: 1px solid#4bb781;
}
.drawerlist_container li:hover {
  border-bottom: 1px solid #fff;
}
.drawerlist_container li a {
  padding: 15px 5px;
  color: #fff;
}
.spacing_container {
  height: 70%;
}
.logo_container {
  position: relative;
  top: 60vh;
}
.sideDrawer_open {
  position: fixed;
  width: 280px;
  max-width: 70%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 200;
  background-color: #333;
  padding: 32px 16px;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
}

.sideDrawer_close {
  position: fixed;
  width: 280px;
  max-width: 70%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 200;
  background-color: #333;
  padding: 32px 16px;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
}

.sideDrawer_close {
  transform: translateX(100%);
}
.sideDrawer_open {
  transform: translateX(0);
}
