.screen_container {
  padding: 10px 0;
  min-height: 100vh;
  background: #696969;
  text-align: center;
  padding-top: 150px;
}
.action_image_container {
  max-width: 800px;
  margin: 10px auto;
  position: relative;

}

.image {
  width: 100%;
  height: auto;
  border-radius: 20px;

}
.logo_container {
  border-radius: 7px;
  height: 170px;
  text-align: left;
}
.logo_container img {
  border-radius: 7px;
  height: 170px;
  width: auto;
  margin: 0 20px 0 50px;

}


.calendar_section_container{ 
  overflow: hidden;
  margin: auto;
  max-width: 880px;
  padding: 10px 0
}
.card_details {
  background: #696969;
  height: 100%;
  max-width: 1000px;
  margin: auto;
  padding: 10px 0



}
.image {
  width: 100%;
  max-width: 800px;
  height: auto;
  margin:  0 0 70px 0;
}
.calendar_class {
  width: 100% !important;
  background: #696969 !important;

}



.calendar_class button:nth-child(6)  {
  color: red;

}
.calendar_class button:nth-child(7)  {
  color: red;

}
.calendar_class button:nth-child(14)  {
  color: red;

}
.calendar_class button:nth-child(13)  {
  color: red;
  

}
.calendar_class button:nth-child(20)  {
  color: red;
  

}
.calendar_class button:nth-child(21)  {
  color: red;
  

}
.calendar_class button:nth-child(28)  {
  color: red;
  

}
.calendar_class button:nth-child(27)  {
  color: red;
  

}
.calendar_class button:nth-child(35)  {
  color: red;
  

}
.calendar_class button:nth-child(34)  {
  color: red;
  

}
.calendar_class button:nth-child(41)  {
  color: red;
  

}
.calendar_class button:nth-child(42)  {
  color: red;
  

}
.calendar_class button {
  color: #c1edcc

}

